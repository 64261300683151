import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import StarIcon from '@mui/icons-material/Star';
import { useNavigate } from 'react-router-dom';
import { path } from 'config/path';
import { isBrowser, isMobile } from "react-device-detect";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function SafeCarouel({store}) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [maxSteps, setMaxSteps] = useState(0);
  const [images, setImages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {   
    //console.log(store);
    if (store.images?.length>0) {
      const imgtype = isMobile ? 2 : 1;
      let imgs = store?.images?.filter(image=>image.type == imgtype); 
      setImages(imgs);
      //console.log(imgs);
      setMaxSteps(imgs?.length);
      setActiveStep(0);
    }
  }, [store]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleClick = (url) => {
    //console.log(url);
    navigate(url);
  }

  return (
    <Box sx={{ 
      pt : {xs:0, md:'3em'},
      pl : {xs:0, md:'3em'} 
    }}>
      <AutoPlaySwipeableViews
        interval={3000}
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={index}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: {xs:'30em', md:'50em'} , 
                  display: 'inline-block',
                }}
              >
                <Box
                  onClick={()=>handleClick(step.note)}
                  sx={{
                    //position: 'absolute',
                    //top: 0,
                    //left: 0,
                    width: '100%',
                    height: {xs:'30em', md:'50em'} , 
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: semi-transparent background for overlay
                    color: 'white', // Text color
                    textAlign: 'center', // Center text alignment
                  }}
                >
                  <img src={`${path.ciHost}/uploads/${store.id}/` + step.filename} width="100%" />
                </Box>
              </Box>
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      {/* <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      /> */}
    </Box>
  );
}

export default SafeCarouel;
