import { forEach } from 'lodash';
import { path } from 'config/path';
import axios from 'axios';

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

export const scrollToValue = (value) => {
  window.scrollTo({
    top: value,
    left: 0,
    behavior: 'smooth',
  });
};

export const matchFormValues = (initValues, values) => {
  const newValues = {};
  forEach(initValues, (_, key) => {
    newValues[key] = values[key] || '';
  });
  return newValues;
};

export const nullToEmpty = (datas) => {
  forEach(datas, (value, key) => {
    if (value === null) datas[key] = '';
  });
  return datas;
};

export const createUrlQuery = (params, without = '') => {
  const queries = {};
  let query = '';
  for (const entry of params.entries()) {
    const [key, value] = entry;
    if (key !== without && value !== '') {
      query += `&${key}=${value}`;
      queries[key] = value;
    }
  }
  if (query !== '') query = query.substring(1);
  return {
    queries,
    query,
  };
};

export const exportToCSV = (data, filename) => {
	const tableHeader = data.header;
  const tableAccessor = data.accessor;
	const tableData = data.data;
	const csvRows = [];
	// Header
	csvRows.push(tableHeader.join(","));

	// Rows
	tableData.forEach((item) => {
		const row = tableAccessor.map((fieldName) =>
			JSON.stringify(item[fieldName], (key, value) => {
				return value ?? "";
			})
		);
    csvRows.push(row.join(','));
	});

  // Create download link and trigger download
  const csvContent = csvRows.join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();
};

export const String2Joined = (str) => {
  return str.split(" ").join("+"); // Use a regular expression to split on one or more spaces
};

export const Joined2String = (str) => {
  return str.split("+").join(" "); // Use a regular expression to split on one or more spaces
};


export const DeleteImage = (id) => {
  axios.get(`${path.ciHost}/index.php/api/v1/product/imgdelete/${id}`)
    .then(response => {
      console.log('image deleted');  
      return true; 
    })
    .catch(error => {
      console.error('image delete error:', error);
      return false;
    });
};

export const EditImage = (image) => {
  console.log(image);
  axios.post(`${path.ciHost}/index.php/api/v1/product/imgedit/${image.id}`,
      { type:image.type, param:image.param, note:image.note }
  )
    .then(response => {
      if (response.data.result) {
        console.log('image updated');
        return true;
      }
    })
    .catch(error => {
      console.error('image edit error:', error);
      return false;
    });
}