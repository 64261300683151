
import { useContext } from "react";
import { useTheme } from '@mui/material/styles';
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import ImageBox from "components/ui/ImageBox";
import SafeCarouel from 'components/ui/carousel';
import Review from "components/ui/review";
import { useNavigate } from "react-router-dom";
import InstagramEmbed from 'components/page/menu/instagramEmbed';
import BookBtn from 'components/ui/bookbtn';
import Contact from './Contact';
import { Stack } from '@mui/system';
import { StoreContext } from "../../layout/HomeLayout/index";

const Home = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { allmenu, store } = useContext(StoreContext);

  //console.log(matchesXs);
  return (
    <Box display='flex' sx={{ 
      mx:'-0.4em', 
      flexWrap:'wrap', 
      fontSize:{xs:'9px', sm:'10px', md:'15px'} 
      }}
    >
      <Box display='flex' width='100%' sx={{ flexDirection:'column', pt:'1em' }}>       
        <Box sx={{ textAlign:'center', px:'3.2em' }}>
          <BookBtn />
        </Box> 
        <Box>
          <SafeCarouel store={store}/>
          {/* <video width="100%" height="auto" playsInline autoPlay loop muted>
            <source src={process.env.PUBLIC_URL + '/images/jubilee/intro.mp4'} type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}
          {/* <ImageBox imageUrl={process.env.PUBLIC_URL + '/images/jubilee/pic2.jpg'} isBg={false} opc={0.0}>
            <Box>&nbsp;</Box>
          </ImageBox> */}
        </Box>
        <Box sx={{ backgroundColor : 'ivory' }}>
          {/* <ImageBox imageUrl={process.env.PUBLIC_URL + '/images/jubilee/hero.jpg'} isBg={true} opc={1.0}> */}
            <Box width='100%' sx={{ 
              py:'1em', 
              textAlign:'center', 
              px:'3em', 
              backgroundColor:'white' 
            }}>
              {/* <Typography variant="h2" sx={{ 
                fontWeight:800,
                pt:'1em',
                pb:'0.5em',
                lineHeight: '1.5em',
                color:'#800020'
                //color : 'white'
              }}>
                ANNOUNCEMENT
              </Typography> */}
              <Typography variant="h2" sx={{ 
                fontWeight:800,
                fontSize:'3em',
                pt:'1em',
                pb:'0.5em',
                lineHeight: '1.5em',
                color:'#800020'
                //color : 'white'
              }}>
                NEW BRANCH COMING IN SOHO
              </Typography>
              <Typography variant='h3' sx={{ lineHeight:'1.5em', py:'1em'}}>
                Exciting News! We are thrilled to announce that Jubilee Hair Salon is celebrating its 20th anniversary with the opening of our new Soho branch. This new location is housed in a historic listed building, and our entire team is working together to create the most comfortable and unique interior possible. We are aiming to open this December, and we can't wait to welcome you to our new Soho branch.
              </Typography>
            </Box>
          {/* </ImageBox> */}
        </Box>
        {/* <Box display='flex' sx={{ alignItems:'flex-end', justifyContent:'center'  }}>
          <BookBtn />
        </Box> */}
        <Box>
          {/* <ImageBox imageUrl={process.env.PUBLIC_URL + '/images/jubilee/hero.jpg'} isBg={true} opc={1.0}> */}
            <Box width='100%' height='100%' display='flex' sx={{
              flexDirection:'column',
              justifyContent:'flex-end',
              py:'1em',
              alignItems:'center',
              px:{xs:'3em', md:'5em'},    
             }}>          
              <Box width='100%' sx={{ py:'1em', textAlign:'center' }}>
                <Typography variant="h1" sx={{ 
                  fontWeight:800,
                  fontSize:'4em',
                  pb:'1em',
                  lineHeight: '1.5em',
                  color:'#4A2E1C',
                  //color : 'white',
                  //textShadow:'-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,1px 1px 0 #fff;'
                }}>
                  About Jubilee Hair Salon
                </Typography>
              </Box>
              <Box>
                <Typography variant="h3" sx={{
                  textAlign:'center',
                  lineHeight:'1.5em',
                  pb:'1em',
                  color:'#4A2E1C'
                  //color : 'white',
                  //textShadow:'-1px -1px 0 #444, 1px -1px 0 #444, -1px 1px 0 #444,1px 1px 0 #444;'
                }}>
                 Since opening in 2004, Jubilee Hair Salon has quickly grown to become one of the premier salons in the area. We pride ourselves on staying true to the fundamentals while continuously striving to offer the latest hair trends to our clients.Our signature services include haircuts, Magic Straightening, and the renowned Korean Digital Perm. We also offer a range of coloring services, from classic dyeing using products like Wella and Japan’s Milbon and Demi, to fashion colours. For men, we specialize in a trendy perm that combines perm and down perm techniques.At Jubilee Hair Salon, our goal is to deliver the best results you can experience in London.
                </Typography>
              </Box>
              {/* <Button 
                onClick={() => navigate('./about')}
                variant='contained' 
                color='secondary'
                sx={{ mb:'1em', width : 'auto'}} 
              >
                About us
              </Button> */}
            </Box>
          {/* </ImageBox> */}
        </Box>  
        <Box display='flex' sx={{ flexWrap:'wrap', alignItems:'center' }}>
          {/* <Box width={{xs:'100%', md:'50%'}}>
            <Review />
          </Box> */}
          <Box display='flex' sx={{
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            p : '1em',
            width:'100%', // {xs:'100%', md:'50%'},
          }}>
            <Typography variant='h2' sx={{ my:'1em', fontSize:'2.5em' }}>
              Opening Hours
            </Typography>
            <Grid container rowSpacing={{xs:3.5, md:5}} columnSpacing={{xs:1, md:3}} 
              sx={{fontSize:'2em', pb:'1em' }}
            >
              <Grid item xs={6} md={6} sx={{ textAlign:'right', lineHeight:'1.5em' }}>
                Monday - Saturday
              </Grid>
              <Grid item xs={6} md={6} sx={{ lineHeight:'1.5em'}}>
                <Box display='flex' sx={{ alignItems:'center', height : '100%', pl:'1em'}}>
                  11 am - 8 pm
                </Box>
              </Grid>
              <Grid item xs={6} md={6} sx={{ textAlign:'right' }}>Sunday</Grid>
              <Grid item xs={6} md={6} sx={{ pl:'1em' }}>
                <Box display='flex' sx={{ alignItems:'center', height : '100%', pl:'1em'}}>Closed</Box> 
              </Grid>
            </Grid>
            {/* <Box sx={{ textAlign:'center', pt:'1em' }}>
              <BookBtn />
            </Box> */}
          </Box>
        </Box>
        <Box sx={{ mt:'2em', px:'2em', textAlign:'center' }}>   
          <Box display='block' width={{xs:'50%', md:'30%'}} sx={{ margin : 'auto'}}>
            <ImageBox imageUrl={process.env.PUBLIC_URL + '/images/jubilee/price-mobile.jpg'} isBg={false} opc={0.0}>&nbsp;</ImageBox>
          </Box>
          {/* <Typography variant='h2' sx={{ my:'1em', fontSize:'2.5em' }}>
            Price List
          </Typography> */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Box display='flex' width='50%' height='100%' sx={{ margin : 'auto', alignItems:'center', justifyContent:'center'}}>
                <ImageBox imageUrl={process.env.PUBLIC_URL + '/images/jubilee/woman-mobile.jpg'} isBg={false} opc={0.0}>&nbsp;</ImageBox>
              </Box>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container rowSpacing={{xs:3.5, md:5}} columnSpacing={{xs:1, md:3}} sx={{fontSize:'2em', py:'1em'}} >
                <Grid item xs={3}>&nbsp;</Grid>
                <Grid item xs={3}><Typography variant='h3'>Senior Stylist</Typography></Grid>
                <Grid item xs={3}><Typography variant='h3'>Top Stylist</Typography></Grid>
                <Grid item xs={3}><Typography variant='h3'>Salon Director</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>Hair Cut</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>From £70</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>From £80</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>From £100</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>Tint</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>From £160</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>From £170</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>From £180</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>Digital Perm</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>From £220</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>From £230</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>From £250</Typography></Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Box display='flex' width='50%' height='100%' sx={{ margin : 'auto', alignItems:'center', justifyContent:'center'}}>
                <ImageBox imageUrl={process.env.PUBLIC_URL + '/images/jubilee/man-mobile.jpg'} isBg={false} opc={0.0}>&nbsp;</ImageBox>
              </Box>
            </Grid>
            <Grid item xs={12} md={9}>
              <Grid container rowSpacing={{xs:3.5, md:5}} columnSpacing={{xs:1, md:3}} sx={{fontSize:'2em', py:'1em'}} >
                <Grid item xs={3}>&nbsp;</Grid>
                <Grid item xs={3}><Typography variant='h3'>Senior Stylist</Typography></Grid>
                <Grid item xs={3}><Typography variant='h3'>Top Stylist</Typography></Grid>
                <Grid item xs={3}><Typography variant='h3'>Salon Director</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>Hair Cut</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>From £55</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>From £60</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>From £80</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>Tint</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>From £130</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>From £140</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>From £150</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>Perm</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>From £135</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>From £155</Typography></Grid>
                <Grid item xs={3}><Typography variant='h4'>From £165</Typography></Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box>
            <Button 
              onClick={() => navigate('./price')}
              variant='contained' 
              color='secondary'
              sx={{ mb:'1em', width : 'auto'}} 
            >
              Discover the complate price
            </Button>
          </Box>
        </Box>
        <Box>
          <Contact/>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
